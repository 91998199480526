@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  background: #eee;
  font-family: 'Poppins', sans-serif;
}
.app {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  padding: 0 20px;

  header {
  }

  .profile {
    display: flex;
    flex-direction: column;

    .profile-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 2;

      .profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        overflow: hidden;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

        img {
          max-width: 100%;
        }
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      position: relative;
      z-index: 1;
      margin-top: -50px;
      padding-top: 50px;
      box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1);

      .saveToContacts {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #fff;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background: #0d6efd;
        margin-bottom: 15px;

        &:hover {
          color: #fff;
          background-color: #0b5ed7;
          border-color: #0a58ca;
        }
      }

      h5 {
      }

      .profile-contact-info {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .profile-contact-detail {
          border-top: 1px dotted #ddd;
          padding: 10px 0;

          &:last-child {
            border-bottom: 1px dotted #ddd;
          }

          a {
            color: #9146ff;
            text-decoration: none;
          }
        }
      }

      .social-media-addresses {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px 20px 20px;

        .profile-social-media {
          width: 100%;
          flex: 50%;
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          align-items: center;
          border-right: 1px dotted #ddd;
          border-bottom: 1px dotted #ddd;
          box-sizing: border-box;

          &:nth-child(2n) {
            border-right: 0;
          }

          &:last-child,
          &:nth-last-child(2) {
            border-bottom: 0;
          }

          a {
            color: #111;

            &[data-provider='facebook'] {
              color: #1877f2;
            }

            &[data-provider='twitter'] {
              color: #1da1f2;
            }

            &[data-provider='linkedin'] {
              color: #0a66c2;
            }

            &[data-provider='instagram'] {
              color: #c32aa3;
            }

            &[data-provider='tiktok'] {
              color: #010101;
            }
          }
        }
      }
    }
  }
}
